<script setup lang="ts">
// For breadcrumbs bar
const title = 'Parts Catalog';
const crumbs: ChapmanCrumb[] = [
    {
        order: 1,
        display: 'Parts',
        link: {
            name: 'parts',
        },
    },
];

// For revolution parts plugin
const pluginHash = 'a2f247fd3a10d13b0800bf8f154c13d8';

onMounted(() => {
    setTimeout(() => {
        const head = document.getElementsByTagName('head')[0];

        const script = document.createElement('script');
        script.innerHTML = `
            window.rp=window.rp||{};rp.l=+new Date;
            rp.hash = '${pluginHash}';
        `;

        const plugin = document.createElement('script');
        plugin.src = 'https://cdn.revolutionparts.com/webstore/plugin.js';

        head.appendChild(script);
        head.appendChild(plugin);
    }, 500);
});

// For meta information in <head></head>
useHead(
    {
        link: [
            {
                rel: 'preload',
                as: 'document',
                href: `https://store-plugin.revolutionparts.com/?hash=${pluginHash}`,
            },
            {
                rel: 'preload',
                as: 'script',
                href: `https://cdn.revolutionparts.com/webstore/plugin.js`,
            },
        ],
    },
    { mode: 'client' }
);
</script>

<template>
    <div>
        <BreadcrumbsBar :crumbs="crumbs" :current="title" />

        <CenterContent padding="30px 2.5rem">
            <ClientOnly>
                <div id="rp_plugin"></div>
            </ClientOnly>
        </CenterContent>
    </div>
</template>
